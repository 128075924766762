@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@media all and (display-mode: standalone) {
    // CSS for Standalone APP
}

body {
    font-size: 12px;
}



.d-header {
    .navbar-brand {
        pointer-events: none;
        line-height: 48px;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #c1c1c1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
 * Sidebar
 */
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    /* Behind the navbar */
    padding: 48px 0 0;

    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;

    /* Scrollable contents if viewport is shorter than content. */
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    --color: #333;
    font-weight: 500;
    color: #333;

    .feather {
        margin-right: 4px;
        color: #999;
    }

    &.active {
        color: #007bff;
    }

    &:hover .feather,
    &.active .feather {
        color: inherit;
    }
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Navbar
 */
.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.filter{
    position: relative;
    &.selected{
        &:after{
            content: '\2713';
            position: absolute;
            right:10px;
            color:#7b2cbe;
        }
    }
    &:before {
        content: '';
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
        background: var(--color);
    }
}


.urlCard {
    --color: #3498db;
    padding: 20px;
    background-color: #fff;
    margin-bottom: 30px;
    color: #999999;
    border: 1px solid #e5e5e5;
    display: block;
    border-bottom: solid 3px var(--color);
    position: relative;

    .starred {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        font-family: cursive;
        cursor: pointer;

        &::before {
            content: '\2606';
        }

        &:hover {
            color: #ffcc36;
        }

        &.active {
            color: #FF912C;
        }

        &.active,
        &:hover {
            &::before {
                content: '\2605';
            }
        }
    }

    &:hover {
        box-shadow: 0 18px 38px rgba(0, 0, 0, 0.1);
    }

    h4 {
        font-size: 20px;
        color: #26547c;
        margin-bottom: 3px;
    }

    p:empty {
        margin: 0;
    }
}


.website-link {
    a {
        color: #006621;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

.tags span {
    font-size: 10px;
    background: #b5b5b5;
    line-height: 2;
    padding: 0 10px;
    border-radius: 5px;
    margin-right: 3px;
    margin-bottom: 5px;
    display: inline-block;
    color: #fff;
}

.d-filters{
    position: sticky;
    top: 48px;
    z-index: 1;
    background: #fff;
    .btn-group{
        margin-right:16px;
        margin-left:auto;
        input{
            display: none;
        }
    }
}


.switch-label {
    line-height: 22px;
    cursor: pointer;
    input[type="checkbox"] {
        position: relative;
        width: 40px;
        height: 20px;
        -webkit-appearance: none;
        background: #c6c6c6;
        border-radius: 100px;
        outline: none;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        transition: .5s;
        vertical-align: middle;
        margin-left: 10px; 
    }

    input:checked[type="checkbox"] {
        background: #7b2cbf;
    }

    input[type="checkbox"]::before {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background: white;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        transform: scale(1.1);
        transition: .5s;
    }

    input:checked[type="checkbox"]::before {
        left: 50%;
    }
}